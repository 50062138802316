import { Text } from "@visx/text"

import { fontWeight } from "src/ui/fontWeight"

export const LineEndIndicator = ({
  x,
  y,
  children,
  color,
}: {
  x: number
  y: number
  children?: string | number
  color: string
}) => {
  return (
    <>
      <circle
        cx={x}
        cy={y}
        r={4}
        fill={color}
        fillOpacity={1}
        stroke={color}
        strokeOpacity={0.2}
        strokeWidth={10}
        style={{ pointerEvents: "none" }}
      />
      <Text
        textAnchor="middle"
        dy={-20}
        x={x}
        y={y}
        style={{ fontWeight: fontWeight.medium }}
      >
        {children}
      </Text>
    </>
  )
}
