/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react"
import styled from "styled-components"

import { AxisBottom, AxisLeft } from "@visx/axis"
import { curveMonotoneX, curveStep } from "@visx/curve"
import { localPoint } from "@visx/event"
import { GridRows } from "@visx/grid"
import { Group } from "@visx/group"
import { ParentSize } from "@visx/responsive"
import { scaleLinear, scaleTime } from "@visx/scale"
import { Area, Bar, Line, LinePath } from "@visx/shape"
import { useTooltip } from "@visx/tooltip"
import { Tooltip } from "@visx/tooltip"
import { bisector, extent } from "d3-array"
import { CurveFactory } from "d3-shape"
import { differenceInHours } from "date-fns"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TClockTypeMaybe } from "src/data/user/user"
import { semanticEmergency } from "src/ui/colors"
import {
  getActiveNoiseThreshold,
  ILineGraphData,
  ITimeScopedThreshold,
} from "src/ui/Graphs/configurationUtils"
import { DataPointTooltip } from "src/ui/Graphs/LineGraph/DataPointTooltip"
import {
  localizedTimeFormatter,
  responsiveGraphXTicks,
  responsiveGraphYTicks,
} from "src/ui/Graphs/utils"
import { formatDate } from "src/utils/l10n"

import { LineEndIndicator } from "./LineEndIndicator"

// accessors
const getXValue = (data: ILineGraphData) => data.datetime
const getYValue = (data: ILineGraphData) => data.value ?? 100
const getYMaxValue = (data: ILineGraphData) => data.max ?? 100
const getYMinValue = (data: ILineGraphData) => data.min ?? 0
const getDefinedValue = (data: ILineGraphData) =>
  data.value === 0 ? true : !!data.value // Make sure to render if the value is exactly 0.

const bisectDate = bisector((data: ILineGraphData) => {
  return getXValue(data)
}).left

const getDateLabel = ({
  date,
  clockType,
}: {
  date: Date
  clockType: TClockTypeMaybe
}) => {
  try {
    const formattedDate = formatDate({
      date: date.toISOString(),
      clockType,
      excludeYear: true,
    })

    return formattedDate
  } catch (err) {
    return date.toDateString()
  }
}

interface IMargin {
  top?: number
  right?: number
  bottom?: number
  left?: number
}
interface ILine {
  stroke: string
}

interface ILineGraph {
  data: ILineGraphData[][] | null
  disableTooltip?: boolean
  stepThresholds?: ITimeScopedThreshold[]
  lineThresholds?: number[]
  unitSymbol?: string
  yMaxOffset?: number
  yMinOffset?: number
  tooltipRoundingFn?: (x: number) => number | string
  curve?: CurveFactory
  hideLeftAxis?: boolean
  axisLeft?: any
  axisBottom?: any
  thresholdLine?: any
  line?: ILine
  timeDomain?: any
  margin?: IMargin
  showLineEndIndicator?: boolean
  grid?: any
  showValuesBelowZero?: boolean
}

const LineGraph = ({
  data,
  disableTooltip = false,
  stepThresholds = [],
  lineThresholds = [],
  unitSymbol,
  yMaxOffset = 20,
  yMinOffset = 20,
  tooltipRoundingFn = Math.round,
  curve = curveMonotoneX,
  hideLeftAxis,
  axisLeft = {},
  axisBottom = {},
  thresholdLine = {},
  line,
  timeDomain = [],
  width,
  height,
  margin,
  showLineEndIndicator,
  grid,
  showValuesBelowZero,
}: ILineGraph & { width: number; height: number }) => {
  const clockType = useGetUser().clock_type
  const flattenedData = useMemo(() => {
    return data?.flatMap((d) => d) || []
  }, [data])

  const {
    tooltipOpen,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<{ pointerX: number; pointerY: number; data: ILineGraphData }>()

  const thresholdValues = useMemo(
    () => stepThresholds.map((o) => o.value) || [],
    [stepThresholds]
  )
  const quietHoursThreshold = useMemo(
    () => stepThresholds.find((th) => th.endHHMM && th.startHHMM),
    [stepThresholds]
  )
  const dayTimeThreshold = useMemo(
    () => stepThresholds.find((th) => !th.endHHMM && !th.startHHMM && th.value),
    [stepThresholds]
  )

  const yMaxScale = useMemo(
    () =>
      Math.max(
        ...flattenedData.map((val) => getYMaxValue(val)),
        ...thresholdValues,
        ...lineThresholds
      ) + yMaxOffset,
    [flattenedData, lineThresholds, thresholdValues, yMaxOffset]
  )

  const yMinScale = useMemo(() => {
    const localMin =
      Math.min(
        ...flattenedData.map((val) => getYMinValue(val)),
        ...thresholdValues,
        ...lineThresholds
      ) - yMinOffset
    return showValuesBelowZero ? localMin : Math.max(0, localMin)
  }, [
    flattenedData,
    lineThresholds,
    thresholdValues,
    yMinOffset,
    showValuesBelowZero,
  ])
  const mMargin = {
    left: 40,
    top: 40,
    right: 40,
    bottom: 60,
    ...margin,
  }

  const xDomain = useMemo(() => {
    if (timeDomain) {
      return [timeDomain[0], timeDomain[1]]
    }
    return extent(flattenedData, getXValue)
  }, [timeDomain, flattenedData])

  const thresholds =
    thresholdValues.length > 0 ? thresholdValues : lineThresholds

  const xDomainDiffInHrs = differenceInHours(xDomain[1], xDomain[0])
  const thresholdStepGraphMaxHours = 168

  const drawThresholdLine =
    xDomainDiffInHrs > thresholdStepGraphMaxHours || stepThresholds.length <= 0

  const xMax = width - mMargin.left - mMargin.right

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: xDomain,
      }),
    [xMax, xDomain]
  )

  const yMax = height - mMargin.top - mMargin.bottom

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [yMinScale, yMaxScale],
        nice: true,
      }),
    [yMax, yMinScale, yMaxScale]
  )

  const handleTooltip = ({
    event,
  }: {
    event: React.TouchEvent | React.MouseEvent
  }) => {
    const point = localPoint(event)
    if (!point) {
      return
    }
    // the mMargin.left subtraction is to get the pointer position to line up
    // with the translated image
    const x = point.x - mMargin.left
    const x0 = xScale.invert(x)
    const index = bisectDate(flattenedData, x0, 1)
    // console.log({ x, x0, index })

    if (flattenedData[index] && flattenedData[index].value) {
      const data = flattenedData[index]
      showTooltip({
        tooltipData: { data, pointerX: point.x, pointerY: point.y },
        tooltipLeft: xScale(getXValue(data)),
        tooltipTop: yScale(getYValue(data)),
      })
    }
  }

  const mLine = {
    stroke: "rgba(0,0,0,0.7)",
    strokeWidth: 2,
    ...line,
  }

  const mAxisLeft = {
    stroke: "rgba(0, 255, 0, 0.2)",
    tickStroke: "transparent",
    hideAxisLine: true,
    hideTicks: true,
    tickLabelProps: () => ({
      fill: "rgba(0, 0, 0, 0.7)",
      textAnchor: "end",
      fontSize: 12,
      fontFamily: "Arial",
      dx: "-0.25em",
      dy: "0.25em",
    }),
    ...axisLeft,
  }

  const mAxisBottom = {
    stroke: "rgba(0, 0, 0, 0.2)",
    strokeWidth: 1,
    tickStroke: "rgba(0, 0, 0, 0.2)",
    tickLabelProps: () => ({
      fill: "rgba(0, 0, 0, 0.7)",
      textAnchor: "end",
      fontSize: 12,
      fontFamily: "Arial",
      dx: "-0.25em",
      dy: "0.25em",
    }),
    ...axisBottom,
  }

  const mGrid = {
    stroke: "rgba(0, 0, 0, 0.1)",
    ...grid,
  }

  const mThresholdLine = {
    stroke: semanticEmergency,
    opacity: 1,
    strokeWidth: 1.2,
    strokeDasharray: "4,5",
    ...thresholdLine,
  }

  const minMaxArea = {
    stroke: "transparent",
    fill: mLine.stroke,
    opacity: 0.3,
  }

  const lastDataPoint = flattenedData[flattenedData.length - 1]

  return (
    <Box>
      <svg width={width} height={height}>
        <Group top={mMargin.top} left={mMargin.left}>
          {!hideLeftAxis && (
            <AxisLeft
              scale={yScale}
              numTicks={responsiveGraphYTicks(height)}
              tickComponent={({ formattedValue, ...tickProps }) => (
                <text {...tickProps}>{formattedValue}</text>
              )}
              {...mAxisLeft}
            />
          )}
        </Group>

        <Group
          top={mMargin.top}
          left={mMargin.left}

          // width={width - mMargin.left * 2}
        >
          {!!drawThresholdLine &&
            thresholds?.map((threshold) => (
              <Line
                key={Math.random()}
                from={{ x: 0, y: yScale(threshold) }}
                to={{ x: xMax, y: yScale(threshold) }}
                style={{ pointerEvents: "none" }}
                {...mThresholdLine}
              />
            ))}

          <GridRows
            top={0}
            left={0}
            scale={yScale}
            width={xMax}
            height={yMax}
            numTicks={responsiveGraphYTicks(height)}
            {...mGrid}
          />
          {!drawThresholdLine && !!dayTimeThreshold && (
            <LinePath
              data={flattenedData}
              x={(d: ILineGraphData) => xScale(getXValue(d))}
              y={(d: ILineGraphData) =>
                yScale(
                  getActiveNoiseThreshold({
                    xVal: d.datetime,
                    dayTimeThreshold,
                    quietTimeThreshold: quietHoursThreshold,
                    timezone: undefined,
                  })
                )
              }
              curve={curveStep}
              {...mThresholdLine}
            />
          )}

          <Group>
            <Area
              data={flattenedData}
              x={(d) => xScale(getXValue(d))}
              y0={(d) => yScale(getYMinValue(d))}
              y1={(d) => yScale(getYMaxValue(d))}
              curve={curve}
              {...minMaxArea}
              defined={(d) => getDefinedValue(d)}
            />

            <LinePath
              data={flattenedData}
              x={(d: ILineGraphData) => xScale(getXValue(d))}
              y={(d: ILineGraphData) => yScale(getYValue(d))}
              curve={curve}
              {...mLine}
              defined={(d) => getDefinedValue(d)}
            />
          </Group>

          <Bar
            x={0}
            y={0}
            width={xMax}
            height={yMax}
            fill="transparent"
            onTouchStart={(event) =>
              handleTooltip({
                event,
              })
            }
            onTouchMove={(event) =>
              handleTooltip({
                event,
              })
            }
            onMouseMove={(event) =>
              handleTooltip({
                event,
              })
            }
            onMouseLeave={(_event) => hideTooltip()}
          />

          <AxisBottom
            top={yMax}
            left={0}
            scale={xScale}
            tickFormat={
              (axisBottom && axisBottom.tickFormat) ||
              localizedTimeFormatter(clockType)
            }
            numTicks={
              (axisBottom && axisBottom.numTicks) ||
              responsiveGraphXTicks(width)
            }
            tickValues={
              axisBottom && axisBottom.showFirstAndLast
                ? (extent(flattenedData, getXValue) as Date[])
                : undefined
            }
          >
            {(axis) => {
              const tickLabelSize = mAxisBottom.tickLabelProps().fontSize
              const tickRotate = 0
              const axisCenter = (axis.axisToPoint.x - axis.axisFromPoint.x) / 2
              return (
                <g>
                  <Line
                    from={{ x: 0, y: 0 }}
                    to={{ x: xMax, y: 0 }}
                    stroke={mAxisBottom.stroke}
                    strokeWidth={mAxisBottom.strokeWidth}
                    style={{ pointerEvents: "none" }}
                  />
                  {axis.ticks.map((tick, i) => {
                    const tickX = tick.to.x
                    const tickY = tick.to.y + tickLabelSize + axis.tickLength
                    return (
                      <Group key={`tick-${tick.value}-${i}`}>
                        <Line
                          from={tick.from}
                          to={tick.to}
                          stroke={mAxisBottom.tickStroke}
                        />
                        <text
                          transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                          fontSize={tickLabelSize}
                          textAnchor="middle"
                          fill={mAxisBottom.tickLabelProps().fill}
                        >
                          {tick.formattedValue}
                        </text>
                      </Group>
                    )
                  })}
                  <text
                    textAnchor="middle"
                    transform={`translate(${axisCenter}, 50)`}
                    fontSize="8"
                  >
                    {axis.label}
                  </text>
                </g>
              )
            }}
          </AxisBottom>

          {showLineEndIndicator && lastDataPoint && (
            <LineEndIndicator
              x={xScale(getXValue(lastDataPoint))}
              y={yScale(getYValue(lastDataPoint))}
              color={mLine.stroke}
            >
              {`${tooltipRoundingFn(getYValue(lastDataPoint))} ${unitSymbol}`}
            </LineEndIndicator>
          )}
        </Group>

        {!disableTooltip && tooltipOpen && tooltipData && (
          <Group top={mMargin.top} left={mMargin.left}>
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: yMax }}
              stroke="rgba(92, 119, 235, 1.000)"
              strokeWidth={2}
              style={{ pointerEvents: "none" }}
              strokeDasharray="2,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop ?? 0 + 1}
              r={4}
              fill="black"
              fillOpacity={0.1}
              stroke="black"
              strokeOpacity={0.1}
              strokeWidth={2}
              style={{ pointerEvents: "none" }}
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill="rgba(92, 119, 235, 1.000)"
              stroke="white"
              strokeWidth={2}
              style={{ pointerEvents: "none" }}
            />
          </Group>
        )}
      </svg>

      {!disableTooltip && tooltipOpen && tooltipData && (
        <>
          <DataPointTooltip
            key={Math.random()}
            top={tooltipData.pointerY}
            left={tooltipData.pointerX}
            avg={tooltipRoundingFn(getYValue(tooltipData.data))}
            min={tooltipRoundingFn(getYMinValue(tooltipData.data))}
            max={tooltipRoundingFn(getYMaxValue(tooltipData.data))}
            date={getDateLabel({ date: tooltipData.data.datetime, clockType })}
            unitSymbol={unitSymbol}
          />

          <Tooltip
            key={Math.random()}
            top={yMax}
            left={tooltipData.pointerX}
            style={{
              display: "none", // this tooltip seems redundant
              position: "absolute",
              width: "fit-content",
              whiteSpace: "nowrap",
              transform: "translate(-50%,0)",
              fontSize: "0.975rem",
              background: "white",
              padding: "0.2rem 0.5rem",
              color: "inherit",
            }}
          >
            {getDateLabel({ date: getXValue(tooltipData.data), clockType })}
          </Tooltip>
        </>
      )}
    </Box>
  )
}

export function LineGraphResponsive(props: ILineGraph) {
  if (!props.data) {
    return null
  }

  return (
    <ParentSize>
      {(parent) => (
        <LineGraph width={parent.width} height={parent.height} {...props} />
      )}
    </ParentSize>
  )
}

// Adding absolute positioning to enforce responsiveness of graphs.
const Box = styled.div`
  position: absolute;
`
